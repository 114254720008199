export default function Carousel() {
    return {
        id: null,
        userId: null,
        email: null,
        name: null,
        password: [],
        username: null,
        rocketChatId: '',
    };
}
