<template>
  <div
    :id="modalName"
    class="modal"
    role="dialog"
    aria-hidden="true"
    data-backdrop="static"
    data-keyboard="false"
  >
    <div class="modal-dialog modal-md modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ $t('module.chatUser') }}</h5>
        </div>
        <form @submit.prevent="submitData()">
          <div class="modal-body">
            <input
              type="hidden"
              class="form-control"
              v-model.number="chatUser.id"
            />
            <div class="form-group row">
              <label class="col-lg-4 col-form-label">{{ $t('common.userId') }}:</label>
              <div class="col-lg-8">
                    <select-picker
                        :changedValue.sync="chatUser.userId"
                        ref="userSelectVue"
                        :id-select-picker="'user_id_' + chatUser.id"
                        :selected-picker="chatUser.userId"
                    ></select-picker>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-lg-4 col-form-label">{{ $t('common.email') }}:</label>
              <div class="col-lg-8">
                <input
                  type="text"
                  class="form-control"
                  v-model="chatUser.email"
                  v-validate="'required'"
                  data-vv-as="Email"
                  name="email"
                  :class="{'is-invalid': errors.has('email') }"
                />
                <div
                  v-show="errors.first('email')"
                  class="invalid-feedback"
                >{{ errors.first('email') }}</div>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-lg-4 col-form-label">{{ $t('common.name') }}:</label>
              <div class="col-lg-8">
                <input
                  type="text"
                  class="form-control"
                  v-model="chatUser.name"
                  v-validate="'required'"
                  data-vv-as="Name"
                  name="name"
                  :class="{'is-invalid': errors.has('name') }"
                />
                <div
                  v-show="errors.first('name')"
                  class="invalid-feedback"
                >{{ errors.first('name') }}</div>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-lg-4 col-form-label">{{ $t('common.username') }}:</label>
              <div class="col-lg-8">
                <input
                  type="text"
                  class="form-control"
                  v-model="chatUser.username"
                  v-validate="'required'"
                  data-vv-as="Username"
                  name="username"
                  :class="{'is-invalid': errors.has('username') }"
                />
                <div
                  v-show="errors.first('username')"
                  class="invalid-feedback"
                >{{ errors.first('username') }}</div>
              </div>
            </div>
            <template v-if="chatUser.id">
                <div class="form-group row">
                    <label class="col-lg-4 col-form-label">{{ $t('common.password') }}:</label>
                    <div class="col-lg-8">
                        <div class="kt-input-icon kt-input-icon--right">
							<input
                                :type="showPassword ? 'text' : 'password'"
                                class="form-control"
                                v-model="chatUser.password"
                                :disabled="true"
                            />
							<span class="kt-input-icon__icon kt-input-icon__icon--right" @click="showPassword = !showPassword">
								<span><i class="la" :class="showPassword ? 'la la-eye' : 'la la-eye-slash'"></i></span>
							</span>
						</div>

                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-lg-4 col-form-label">{{ $t('common.rocketChatId') }}:</label>
                    <div class="col-lg-8">
                        <input
                        type="text"
                        class="form-control"
                        v-model="chatUser.rocketChatId"
                        :disabled="true"
                        />
                    </div>
                </div>
            </template>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-clean btn-bold btn-upper btn-font-md"
              @click="clearData()"
            >Close</button>
            <button
              type="submit"
              class="btn btn-default btn-bold btn-upper btn-font-md"
            >Save</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import ChatUser from "../../../model/chat-user-model";
import { RepositoryFactory } from "../../../repositories/RepositoryFactory";
const ChatUserRepository = RepositoryFactory.get("chatUser");
import { mapState, mapMutations, mapActions } from 'vuex';
import SelectPicker from "../../_general/SelectPicker";
export default {
  components: {
      SelectPicker,
  },
  data() {
    return {
      chatUser: new ChatUser(),
      showPassword: false
    };
  },
  watch: {
      "chatUser.userId"(val) {
            const data = this.$refs.userSelectVue.getDataById(val);
            const firstName = (data.firstName) ? data.firstName : '';
            const lastName = (data.lastName) ? data.lastName : '';
            this.chatUser.email = data.email;
            this.chatUser.username = data.login;
            this.chatUser.name = firstName+ ' '+lastName;
      }
  },
  provide() {
    return {
      $validator: this.$validator
    };
  },
  computed: {
    ...mapState('MasterStore', {
      modalName: state => state.modalName,
      dataEdit: state => state.dataEdit
    }),
  },
  methods: {
    ...mapActions({
      createNew: 'MasterStore/createNew',
      updateById: 'MasterStore/updateById'
    }),
    ...mapMutations({
      clearData: 'MasterStore/CLEAR_DATA'
    }),
    updateData: function () {
      if (this.dataEdit) {
        this.chatUser = Object.assign({}, this.dataEdit);
      } else {
        this.chatUser.password = 'Dummy Password';
        this.chatUser.rocketChatId = 'Dummy Chat Id';
      }
    },
    submitData: function () {
      this.$validator.validateAll().then(result => {
        if (result) {
          if (this.chatUser.id) {
            this.updateById({ repository: ChatUserRepository.update, data: this.chatUser });
          } else {
            this.createNew({ repository: ChatUserRepository.create, data: this.chatUser });
          }
          return;
        }
      });
    }
  },
  mounted() {
    $("#" + this.modalName).modal();
    this.updateData();
  }
}
</script>
